export const USER_TAG = 'USER_TAG';
export const PROJECTS_LIST_TAG = 'PROJECT_LIST_TAG';
export const APP_PROJECTS_TAG = 'APP_PROJECTS_TAG';
export const PROJECT_TAG = 'PROJECT_TAG';
export const APPS_LIST_TAG = 'APPS_LIST_TAG';
export const APP_TAG = 'APP_TAG';
export const THREAD_TAG = 'THREAD_TAG';
export const THREADS_LIST_TAG = 'THREADS_LIST_TAG';
export const THREADS_HISTORY_TAG = 'THREADS_HISTORY_TAG';
export const NEW_MESSAGE_TAG = 'NEW_MESSAGE_TAG';
export const THREAD_MESSAGES_TAG = 'THREAD_MESSAGES_TAG';
export const VAULTS_LIST_TAG = 'VAULTS_LIST_TAG';
export const FAVOURITE_VAULTS_TAG = 'FAVOURITE_VAULTS_TAG';
export const VAULT_TAG = 'VAULT_TAG';
export const MODELS_LIST_TAG = 'MODELS_LIST_TAG';
export const LLM_MODELS_LIST_TAG = 'LLM_MODELS_LIST_TAG';
export const FAVOURITE_APPS_LIST_TAG = 'FAVOURITE_APPS_LIST_TAG';
export const OAUTH_CLIENTS_TAG = 'OAUTH_CLIENTS_TAG';
export const APP_TAGS_TAG = 'APP_TAGS_TAG';
export const SUBSCRIBES_TAG = 'SUBSCRIBES_TAG';
export const PRODUCTS_TAG = 'PRODUCTS_TAG';
export const SUBSCRIBES_URL_TAG = 'SUBSCRIBES_URL_TAG';
export const GENERATION_TYPES_TAG = 'GENERATION_TYPES_TAG';
