import {
	I18N,
	createPluralize,
	useTranslate as useTranslateBase,
	useI18N as useI18nBase,
	I18NProvider,
	TaggedText
} from '@ayub-begimkulov/i18n';

import { getCookie, setCookie } from '../cookies';

export const localeCookieKey = 'locale';

export const getLocale = () => (getCookie(localeCookieKey) as 'en' | 'ru' | undefined) || 'ru';

export const setCookieLocale = (locale: 'en' | 'ru') => {
	setCookie(localeCookieKey, locale);
};

const locale = getLocale();

const pluralizeEn = createPluralize('en');
const pluralizeRu = createPluralize('ru');

const loadEn = () => import('./locale/en').then((module) => module.default);
const loadRu = () => import('./locale/ru').then((module) => module.default);

export const i18n = new I18N({
	defaultLang: locale === 'en' || locale === 'ru' ? locale : 'ru',
	languages: {
		en: {
			keyset: loadEn,
			pluralize: pluralizeEn
		},
		ru: {
			keyset: loadRu,
			pluralize: pluralizeRu
		}
	}
});

export const useTranslate = useTranslateBase<typeof i18n>;
export const useI18n = useI18nBase<typeof i18n>;

export { I18NProvider, TaggedText };
