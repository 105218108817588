import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { TVault, TVaultsFilterParams } from './types';

type TVaultsState = {
	vaults: TVault[];
	filter: TVaultsFilterParams;
	page: number;
	tags: { label: string; value?: string }[];
};

const initialState: TVaultsState = {
	vaults: [],
	filter: {},
	page: 1,
	tags: []
};

export const vaultsSlice = createSlice({
	name: 'vaults',
	initialState,
	reducers: {
		setVaultsList: (state, action: PayloadAction<TVault[]>) => {
			state.vaults = action.payload;
		},
		setVaultsPage: (state, action: PayloadAction<number>) => {
			state.page = action.payload;
		},
		setVaultsFilter: (state, action: PayloadAction<TVaultsFilterParams>) => {
			state.filter = { ...action.payload };
			state.page = 1;
		},
		updateVaultsList: (state, action: PayloadAction<TVault[]>) => {
			state.vaults = [...state.vaults, ...action.payload];
		},
		updateVaultIsFavourite: (state, action: PayloadAction<{ id: string; isFavourite: boolean }>) => {
			const vault = state.vaults.find((vault) => vault.id === action.payload.id);
			if (vault) {
				vault.isFavourite = action.payload.isFavourite;
			}
		}
	}
	// extraReducers: (builder) => {
	// 	builder.addMatcher(vaultsApi.endpoints.getTags.matchFulfilled, (state, action: PayloadAction<TTagsDTO>) => {
	// 		state.tags = [{ label: 'Все' }, ...action.payload.docs.map((tag) => ({ label: tag.name, value: tag.id }))];
	// 	});
	// }
});

export const { setVaultsList, updateVaultsList, updateVaultIsFavourite, setVaultsPage, setVaultsFilter } =
	vaultsSlice.actions;
