import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/model';
import { selectCurrentTab, selectActions, selectAppSettings } from '../../model/selectors';
import { Form, Input, FormInstance, Select, Switch, Button, ConfigProvider, Popconfirm } from 'antd';
import { updateActionData, updateEmoji } from '../../model/slice';
import { EAppFormFields } from '../../model/types';
import { useTranslate } from 'shared/lib/i18n';
import cx from './EditAppForm.module.css';
import { useGetTagsQuery } from 'entities/apps';
import { ActionFormItem } from '../ActionFormItem';
import { OnNodesDelete, Node } from 'reactflow';
import { DeleteOutlined, SmallLogoIcon } from 'shared/ui/Icons';
import { methodDebounce } from 'shared/lib';
import { TAGS_MAX_COUNT } from '../../model/constants';
import { CustomPopover } from 'shared/ui';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

interface IEditAppFormProps {
	onNodesDelete: OnNodesDelete;
	form: FormInstance;
	onFinish: () => void;
}

const EditAppFormComponent = ({ form, onFinish, onNodesDelete }: IEditAppFormProps) => {
	const t = useTranslate();
	const dispatch = useAppDispatch();
	const actions = useAppSelector(selectActions);
	const currentTab = useAppSelector(selectCurrentTab);
	const { name, description, tags, isPrivate, emoji } = useAppSelector(selectAppSettings);
	const [editedActionKey, setEditedActionKey] = useState<string>('');
	const [openPicker, setOpenPicker] = useState(false);
	const { tagList } = useGetTagsQuery(undefined, {
		selectFromResult: ({ data }) => {
			return { tagList: data?.docs.map((tag) => ({ label: tag.name, value: tag.id })) ?? [] };
		}
	});

	const formTags = Form.useWatch('tags', form);

	useEffect(() => {
		if (editedActionKey === 'slug') {
			form.resetFields();
		}
	}, [editedActionKey, actions]);

	const handleFieldChange = (actionId: string, path: string[], value: any) => {
		const fullPath = path.join('.');
		dispatch(updateActionData({ actionId, path: fullPath, value }));
		const targetKey = path[path.length - 1];
		if (targetKey) {
			setEditedActionKey(targetKey);
		}
	};

	const handleEmojiSelect = (emoji: any) => {
		if ('native' in emoji) {
			dispatch(updateEmoji(emoji.native));
			form.setFieldValue(EAppFormFields.EMOJI, emoji.native);
			setOpenPicker(false);
		}
	};

	const debouncedHandleFieldChange: (...args: Parameters<typeof handleFieldChange>) => void = useCallback(
		methodDebounce((actionId: string, path: string[], value: any) => handleFieldChange(actionId, path, value), 300),
		[handleFieldChange]
	);

	const deleteActionHandler = (action: Node) => {
		onNodesDelete([action]);
	};

	const content = useMemo(() => {
		return (
			<div>
				<Picker data={data} onEmojiSelect={handleEmojiSelect} theme='light' />
				<Button
					type='text'
					onClick={() => {
						dispatch(updateEmoji(''));
						form.setFieldValue(EAppFormFields.EMOJI, '');
						setOpenPicker(false);
					}}
					className='mt-2'
				>
					{t('default')}
				</Button>
			</div>
		);
	}, [data, emoji]);

	return (
		<div className='px-5 py-6 overflow-auto mt-[57px]'>
			<ConfigProvider
				theme={{
					components: {
						Form: {
							verticalLabelPadding: 0
						}
					}
				}}
			>
				<Form layout='vertical' onFinish={onFinish} form={form} className={cx.root} requiredMark={false}>
					<div className={currentTab === 'settings' ? '' : 'hidden'}>
						<Form.Item
							label={<span className='text-sm font-medium mb-2'>{t('icon')}</span>}
							name={EAppFormFields.EMOJI}
							initialValue={emoji}
							className='!mb-3'
						>
							<CustomPopover
								destroyTooltipOnHide={true}
								trigger='click'
								open={openPicker}
								content={content}
								onOpenChange={(newOpen) => setOpenPicker(newOpen)}
								arrow={false}
							>
								<div className='w-[48px] h-[48px] min-w-[48px] max-h-[48px] text-4xl rounded-md hover:bg-gray-bg-hover flex items-center justify-center overflow-hidden cursor-pointer'>
									{emoji ? (
										<span>{emoji}</span>
									) : (
										<span className='text-primary-active'>
											<SmallLogoIcon />
										</span>
									)}
								</div>
							</CustomPopover>
						</Form.Item>
						<Form.Item
							label={
								<span className='text-sm font-medium'>
									{t('title')}
									<span className='ml-1 text-error'>*</span>
								</span>
							}
							name={EAppFormFields.NAME}
							initialValue={name}
							rules={[
								{
									required: true,
									message: t('error.required_field')
								},
								{ min: 3, message: `${t('error.min_length')} 3` },
								{
									max: 200,
									message: `${t('error.max_length')} 200`
								},
								{
									validator: (_: any, value: string) => {
										if (value && value.trim() === '') {
											return Promise.reject(new Error(t('error.cannot_only_spaces')));
										}
										return Promise.resolve();
									}
								}
							]}
							className={`${cx.text}`}
						>
							<Input
								count={{
									show: ({ value, count, maxLength }) => (
										<span className='absolute right-0 bottom-[100%] pb-2 text-xs'>
											{count}/{maxLength}
										</span>
									),
									max: 200
								}}
							/>
						</Form.Item>
						<Form.Item
							label={
								<span className='text-sm font-medium'>
									{t('description')}
									<span className='ml-1 text-error'>*</span>
								</span>
							}
							name={EAppFormFields.DESCRIPTION}
							className={cx.text}
							initialValue={description}
							rules={[
								{
									required: true,
									message: t('error.required_field')
								},
								{ min: 3, message: `${t('error.min_length')} 3` },
								{
									max: 200,
									message: `${t('error.max_length')} 200`
								}
							]}
						>
							<Input.TextArea
								rows={2}
								count={{
									show: ({ value, count, maxLength }) => (
										<span className='absolute right-0 bottom-[100%] pb-2 text-xs'>
											{count}/{maxLength}
										</span>
									),
									max: 200
								}}
							/>
						</Form.Item>
						<div className={cx.text}>
							<span className='absolute right-0 top-0 pt-2 text-xs opacity-50'>
								{formTags?.length || 0}/{TAGS_MAX_COUNT}
							</span>
							<Form.Item
								label={<span className='text-sm font-medium'>{t('categories')}</span>}
								name={EAppFormFields.TAGS}
								initialValue={tags}
							>
								<Select
									mode='multiple'
									allowClear
									maxCount={TAGS_MAX_COUNT}
									style={{ width: '100%' }}
									options={tagList}
								></Select>
							</Form.Item>
						</div>
						<Form.Item
							label='Приватный'
							name={EAppFormFields.IS_PRIVATE}
							initialValue={isPrivate}
							rules={[]}
							hidden={true}
						>
							<Switch />
						</Form.Item>
					</div>
					<div className={currentTab === 'steps' ? '' : 'hidden'}>
						{actions?.map((action, index) => (
							<div key={`${action.id}_${index}`} className={action.selected ? '' : 'hidden'}>
								<div className='flex justify-between text-base font-medium'>
									{action.type === 'gpt_call' && <div>{t('gpt_call')}</div>}
									{action.type === 'api_call' && <div>{t('api_call')}</div>}
									{action.type === 'mailing_call' && <div>{t('mailing_call')}</div>}
									{action.type === 'vault_call' && <div>{t('vault_call')}</div>}
									{action.type === 'initial' && <div>{t('select_step_type')}</div>}
									{action.type !== 'idea' && (
										<Popconfirm
											title={<span>{t('delete_step')}?</span>}
											onConfirm={() => deleteActionHandler(action)}
											cancelButtonProps={{ type: 'text' }}
											okText={t('delete')}
											cancelText={t('cancel')}
										>
											<Button
												type='text'
												shape='circle'
												icon={<DeleteOutlined />}
												className='hover:bg-white opacity-25 ml-auto'
											></Button>
										</Popconfirm>
									)}
								</div>
								<ActionFormItem
									data={action.data}
									actionType={action.type}
									path={['actions', String(index)]}
									actionId={action.id}
									onChange={(path: string[], value: any) => debouncedHandleFieldChange(action.id, path, value)}
									form={form}
									actionIndex={index}
								/>
							</div>
						))}
					</div>
				</Form>
			</ConfigProvider>
		</div>
	);
};

export const EditAppForm = memo(EditAppFormComponent);
