import { baseApi } from 'shared/api/baseApi';
import { FAVOURITE_VAULTS_TAG, VAULT_TAG, VAULTS_LIST_TAG } from 'shared/api/tags';
import { TVaultsDTO } from './type';
import qs from 'qs';
import { TVault } from '../model/types';
import { VAULTS_PER_PAGE } from '../model/constants';
import { setVaultsList, updateVaultsList } from '../model/slice';
export const vaultsApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getVaults: build.query<TVaultsDTO, { tag?: string; search?: string; page: number; vaultsPerPage?: number }>({
			query: ({ tag, search, page, vaultsPerPage }) => {
				let query: any = {
					name: {
						contains: search
					}
				};
				if (tag) {
					query = {
						...query,
						tags: {
							equals: tag
						}
					};
				}
				const stringifiedQuery = qs.stringify(
					{
						where: query,
						limit: vaultsPerPage ?? VAULTS_PER_PAGE,
						page
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `vaults${stringifiedQuery}`
				};
			},
			onQueryStarted: async (payload, { dispatch, getState, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled;
					if (payload.page !== 1) {
						dispatch(updateVaultsList(data.docs));
					} else {
						dispatch(setVaultsList(data.docs));
					}
				} catch (err) {
					console.error(err);
				}
			},
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName;
			},
			merge: (currentCache, newItems, { arg }) => {
				if (arg.page !== 1) {
					return {
						...currentCache,
						...newItems,
						docs: [...currentCache.docs, ...newItems.docs]
					};
					currentCache.docs.push(...newItems.docs);
				}
				return newItems;
			},
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			providesTags: [VAULTS_LIST_TAG]
		}),
		getVault: build.query<TVault, string>({
			query: (id) => ({
				url: `vaults/${id}`
			}),
			providesTags: [VAULT_TAG]
		}),
		getFavouriteVaults: build.query<TVault[], void>({
			query: () => ({
				url: `vaults/favourites`
			}),
			providesTags: [FAVOURITE_VAULTS_TAG]
		})
		// updateIsFavouriteVault: build.mutation<void, { id: string; isFavourite: boolean }>({
		// 	query: ({ id, isFavourite }) => {
		// 		return isFavourite
		// 			? {
		// 					url: 'users/favourites',
		// 					method: 'POST',
		// 					data: { id }
		// 			  }
		// 			: {
		// 					url: `users/favourites/${id}`,
		// 					method: 'DELETE'
		// 			  };
		// 	},
		// 	invalidatesTags: [VAULTS_LIST_TAG, FAVOURITE_VAULTS_TAG]
		// })
	})
});

export const { useGetVaultsQuery, useLazyGetVaultsQuery, useLazyGetVaultQuery } = vaultsApi;
