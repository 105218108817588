import React, { FC, memo, useMemo, useRef } from 'react';
import { Button, Form, FormInstance, Input, InputNumber, Radio, Select, Switch, Checkbox } from 'antd';
import { DeleteOutlined, PlusOutlined, PlusCircleOutlined, MinusCircleOutlined } from 'shared/ui/Icons';
import { useAppDispatch, useAppSelector } from 'shared/model';
import { addEmptyItemToArray, removeArrayItem } from '../../model/slice';
import { EAppFormFields } from '../../model/types';
import { selectSlugs } from '../../model/selectors';
import { useTranslate } from 'shared/lib/i18n';
import { LabelWithInfo } from 'shared/ui/LabelWithInfo';
import { METHOD_OPTIONS } from '../../model/constants';
import { useGetLlmModelsQuery, useGetModelsQuery } from 'entities/models';
import { CustomCollapse } from 'shared/ui';
import { validateNotEmpty } from 'shared/lib';
import { VaultSelect } from '../VaultSelect';

interface IActionFormItem {
	data: Record<string, any>;
	path: string[];
	actionId: string;
	actionType?: string;
	onChange: (path: string[], value: any) => void;
	form: FormInstance;
	actionIndex: number;
}

const ActionFormItemComponent: FC<IActionFormItem> = ({
	data,
	path = [],
	actionId,
	actionType,
	onChange,
	form,
	actionIndex
}) => {
	const t = useTranslate();
	const dispatch = useAppDispatch();
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
	const slugs = useAppSelector(selectSlugs);
	const { llmModels } = useGetLlmModelsQuery(undefined, {
		selectFromResult: ({ data }): { llmModels: { value: string; label: string }[] } => {
			return {
				llmModels: data?.map((model) => ({ value: model.id, label: model.name })) ?? []
			};
		}
	});

	const { models } = useGetModelsQuery(undefined, {
		selectFromResult: ({ data }): { models: { value: string; label: string }[] } => {
			return {
				models:
					data
						?.filter((model) => model.vendor === 'openai')
						.map((model) => ({ value: model.codeId, label: model.name })) ?? []
			};
		}
	});

	const resultViewOptions = useMemo(
		() => [
			{
				label: t('dynamic_text'),
				value: 'dynamic_text'
			},
			{
				label: t('static_text'),
				value: 'static_text'
			},
			{
				label: t('choice'),
				value: 'choice'
			}
		],
		[t]
	);

	const handleVariableInsert = (path: string[], variable: string, prompt: any) => {
		if (textAreaRef.current) {
			const { selectionStart, selectionEnd } = textAreaRef.current;
			const newPrompt = prompt
				? `${prompt.substring(0, selectionStart)}{{ ${variable} }}${prompt.substring(selectionEnd)}`
				: `{{ ${variable} }}`;
			form.setFieldValue(path, newPrompt);
			onChange(path, newPrompt);
			setTimeout(() => {
				if (textAreaRef.current) {
					textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd = selectionStart + variable.length + 6;
					textAreaRef.current.focus();
				}
			}, 0);
		}
	};

	const handleAddHeaderItem = () => {
		const emptyItem = Object.keys(data.headers[0] || {}).reduce(
			(acc, key) => {
				acc[key] = '';
				return acc;
			},
			{} as Record<string, any>
		);
		dispatch(addEmptyItemToArray({ actionId, key: 'headers', item: emptyItem }));
	};

	const handleRemoveHeaderItem = (name: number) => {
		dispatch(removeArrayItem({ actionId, key: 'headers', itemIndex: name }));
	};

	const renderFields = useMemo(() => {
		if (actionType === 'idea') {
			return (
				<>
					<Form.Item
						label={t('hint')}
						name={[...path, EAppFormFields.PLACEHOLDER]}
						initialValue={data[EAppFormFields.PLACEHOLDER]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.PLACEHOLDER], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={EAppFormFields.BLOCK_TYPE}
						name={[...path, EAppFormFields.BLOCK_TYPE]}
						initialValue={data[EAppFormFields.BLOCK_TYPE]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							}
						]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={EAppFormFields.NEXT_SLUG}
						name={[...path, EAppFormFields.NEXT_SLUG]}
						initialValue={data[EAppFormFields.NEXT_SLUG]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={EAppFormFields.SLUG}
						name={[...path, EAppFormFields.SLUG]}
						initialValue={data[EAppFormFields.SLUG]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
					</Form.Item>
				</>
			);
		}
		if (actionType === 'gpt_call') {
			return (
				<>
					<Form.Item
						label={EAppFormFields.NEXT_SLUG}
						name={[...path, EAppFormFields.NEXT_SLUG]}
						initialValue={data[EAppFormFields.NEXT_SLUG]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={EAppFormFields.BLOCK_TYPE}
						name={[...path, EAppFormFields.BLOCK_TYPE]}
						initialValue={data[EAppFormFields.BLOCK_TYPE]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							}
						]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
						name={[...path, EAppFormFields.BLOCK_NAME]}
						initialValue={data[EAppFormFields.BLOCK_NAME]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('dynamic_name')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('dynamic_name.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.SLUG]}
						initialValue={data[EAppFormFields.SLUG]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							},
							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={<span>{t('description')}</span>}
						name={[...path, EAppFormFields.DESCRIPTION]}
						initialValue={data[EAppFormFields.DESCRIPTION]}
						rules={[
							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input.TextArea
							autoSize={{ minRows: 2, maxRows: 4 }}
							onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('result_view')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('result_view.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.TYPE]}
						initialValue={data[EAppFormFields.TYPE]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							}
						]}
					>
						<Radio.Group onChange={(e) => onChange([...path, EAppFormFields.TYPE], e.target.value)}>
							{resultViewOptions.map((option) => (
								<Radio key={`${option.label}_${option.value}`} value={option.value}>
									{option.label}
								</Radio>
							))}
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('prompt')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('prompt.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.PROMPT]}
						initialValue={data[EAppFormFields.PROMPT]}
						className='mb-2'
						rules={[
							{
								required: true,
								message: t('error.required_field')
							},

							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input.TextArea
							autoSize={{ minRows: 4 }}
							ref={(input) => (textAreaRef.current = input?.resizableTextArea?.textArea || null)}
							onChange={(e) => onChange([...path, EAppFormFields.PROMPT], e.target.value)}
						/>
					</Form.Item>
					<div className='flex flex-wrap gap-x-2.5 gap-y-1.5 mb-4'>
						{slugs?.slice(0, actionIndex).map((slug) => (
							<div
								key={slug}
								className='cursor-pointer text-primary-font px-2 py-0.5 rounded-[33px] text-xs leading-5 bg-primary-bg-hover max-w-[60px] min-w-[60px] text-center overflow-hidden text-ellipsis whitespace-nowrap'
								onClick={() =>
									handleVariableInsert([...path, EAppFormFields.PROMPT], slug, data[EAppFormFields.PROMPT])
								}
							>
								{slug}
							</div>
						))}
					</div>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('model')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('model.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.MODEL]}
						initialValue={data[EAppFormFields.MODEL]}
						rules={[{ required: true, message: t('error.required_field') }]}
					>
						<Select onChange={(value) => onChange([...path, EAppFormFields.MODEL], value)} options={models}></Select>
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={<span>{t('visibility')}</span>}
								tooltipText={t('visibility.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.IS_HIDDEN]}
						initialValue={data[EAppFormFields.IS_HIDDEN]}
						rules={[]}
					>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={<span>{t('autonext')}</span>}
								tooltipText={t('autonext.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.AUTONEXT]}
						initialValue={data[EAppFormFields.AUTONEXT]}
						rules={[]}
					>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
					</Form.Item>
					<CustomCollapse
						className='mt-3'
						ghost
						expandIcon={(panelProps) => {
							return panelProps.isActive ? (
								<MinusCircleOutlined className='!text-base !font-bold opacity-60' />
							) : (
								<PlusCircleOutlined className='!text-base !font-bold opacity-60' />
							);
						}}
						items={[
							{
								label: (
									<div>
										<span className='font-bold opacity-60'>{t('advanced')}</span>
										<span className='ml-1 font-bold opacity-60 lowercase'>{t('settings')}</span>
									</div>
								),
								children: (
									<>
										<Form.Item
											label={<span>{t('max_tokens')}</span>}
											name={[...path, EAppFormFields.MAX_TOKENS]}
											initialValue={data[EAppFormFields.MAX_TOKENS]}
											rules={[]}
										>
											<InputNumber
												min='0'
												onChange={(value) => onChange([...path, EAppFormFields.MAX_TOKENS], value)}
												className='w-full'
											/>
										</Form.Item>
										<Form.Item
											label={<span>{t('extract_images')}</span>}
											name={[...path, EAppFormFields.EXTRACT_IMAGES]}
											initialValue={data[EAppFormFields.EXTRACT_IMAGES]}
											rules={[]}
										>
											<Switch onChange={(e) => onChange([...path, EAppFormFields.EXTRACT_IMAGES], e)} />
										</Form.Item>
										<Form.Item
											label={
												<span>
													{t('content_conversation_type')}
													<span className='ml-1 text-error'>*</span>
												</span>
											}
											name={[...path, EAppFormFields.CONTENT_CONVERSATION_TYPE]}
											initialValue={data[EAppFormFields.CONTENT_CONVERSATION_TYPE]}
											rules={[{ required: true, message: t('error.required_field') }]}
										>
											<Select
												onChange={(value) => onChange([...path, EAppFormFields.CONTENT_CONVERSATION_TYPE], value)}
												options={[
													{
														label: 'Not converted',
														value: 'notConverted'
													},
													{
														label: 'to JSON',
														value: 'toJson'
													}
												]}
											></Select>
										</Form.Item>
										<Form.Item
											label={<span>{t('temperature')}</span>}
											name={[...path, EAppFormFields.TEMPERATURE]}
											initialValue={`${data[EAppFormFields.TEMPERATURE]}`}
											rules={[]}
										>
											<InputNumber
												min='0.1'
												max='1'
												step='0.1'
												onChange={(value) => onChange([...path, EAppFormFields.TEMPERATURE], value)}
												className='w-full'
												stringMode
											/>
										</Form.Item>
									</>
								)
							}
						]}
					></CustomCollapse>
				</>
			);
		}

		if (actionType === 'jsonata_call') {
			return (
				<>
					<Form.Item
						label={EAppFormFields.NEXT_SLUG}
						name={[...path, EAppFormFields.NEXT_SLUG]}
						initialValue={data[EAppFormFields.NEXT_SLUG]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={EAppFormFields.BLOCK_TYPE}
						name={[...path, EAppFormFields.BLOCK_TYPE]}
						initialValue={data[EAppFormFields.BLOCK_TYPE]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							}
						]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
						name={[...path, EAppFormFields.BLOCK_NAME]}
						initialValue={data[EAppFormFields.BLOCK_NAME]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('dynamic_name')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('dynamic_name.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.SLUG]}
						initialValue={data[EAppFormFields.SLUG]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							},
							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={<span>{t('description')}</span>}
						name={[...path, EAppFormFields.DESCRIPTION]}
						initialValue={data[EAppFormFields.DESCRIPTION]}
						rules={[
							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input.TextArea
							autoSize={{ minRows: 2, maxRows: 4 }}
							onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('result_view')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('result_view.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.TYPE]}
						initialValue={data[EAppFormFields.TYPE]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							}
						]}
					>
						<Radio.Group onChange={(e) => onChange([...path, EAppFormFields.TYPE], e.target.value)}>
							{resultViewOptions.map((option) => (
								<Radio key={`${option.label}_${option.value}`} value={option.value}>
									{option.label}
								</Radio>
							))}
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										Expression
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('jsonata_call.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.EXPRESSION]}
						initialValue={data[EAppFormFields.EXPRESSION]}
						className='mb-2'
						rules={[
							{
								required: true,
								message: t('error.required_field')
							},

							{ min: 1, message: `${t('error.min_length')} 1` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input.TextArea rows={4} onChange={(e) => onChange([...path, EAppFormFields.EXPRESSION], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={<span>{t('visibility')}</span>}
								tooltipText={t('visibility.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.IS_HIDDEN]}
						initialValue={data[EAppFormFields.IS_HIDDEN]}
						rules={[]}
					>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={<span>{t('autonext')}</span>}
								tooltipText={t('autonext.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.AUTONEXT]}
						initialValue={data[EAppFormFields.AUTONEXT]}
						rules={[]}
					>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
					</Form.Item>
				</>
			);
		}

		if (actionType === 'llm_call') {
			return (
				<>
					<Form.Item
						label={EAppFormFields.NEXT_SLUG}
						name={[...path, EAppFormFields.NEXT_SLUG]}
						initialValue={data[EAppFormFields.NEXT_SLUG]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={EAppFormFields.BLOCK_TYPE}
						name={[...path, EAppFormFields.BLOCK_TYPE]}
						initialValue={data[EAppFormFields.BLOCK_TYPE]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							}
						]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
						name={[...path, EAppFormFields.BLOCK_NAME]}
						initialValue={data[EAppFormFields.BLOCK_NAME]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('dynamic_name')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('dynamic_name.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.SLUG]}
						initialValue={data[EAppFormFields.SLUG]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							},
							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={<span>{t('description')}</span>}
						name={[...path, EAppFormFields.DESCRIPTION]}
						initialValue={data[EAppFormFields.DESCRIPTION]}
						rules={[
							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input.TextArea
							autoSize={{ minRows: 2, maxRows: 4 }}
							onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('result_view')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('result_view.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.TYPE]}
						initialValue={data[EAppFormFields.TYPE]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							}
						]}
					>
						<Radio.Group onChange={(e) => onChange([...path, EAppFormFields.TYPE], e.target.value)}>
							{resultViewOptions.map((option) => (
								<Radio key={`${option.label}_${option.value}`} value={option.value}>
									{option.label}
								</Radio>
							))}
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('prompt')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('prompt.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.PROMPT]}
						initialValue={data[EAppFormFields.PROMPT]}
						className='mb-2'
						rules={[
							{
								required: true,
								message: t('error.required_field')
							},

							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input.TextArea
							autoSize={{ minRows: 4 }}
							ref={(input) => (textAreaRef.current = input?.resizableTextArea?.textArea || null)}
							onChange={(e) => onChange([...path, EAppFormFields.PROMPT], e.target.value)}
						/>
					</Form.Item>
					<div className='flex flex-wrap gap-x-2.5 gap-y-1.5 mb-4'>
						{slugs?.slice(0, actionIndex).map((slug) => (
							<div
								key={slug}
								className='cursor-pointer text-primary-font px-2 py-0.5 rounded-[33px] text-xs leading-5 bg-primary-bg-hover max-w-[60px] min-w-[60px] text-center overflow-hidden text-ellipsis whitespace-nowrap'
								onClick={() =>
									handleVariableInsert([...path, EAppFormFields.PROMPT], slug, data[EAppFormFields.PROMPT])
								}
							>
								{slug}
							</div>
						))}
					</div>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('model')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('model.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.MODEL]}
						initialValue={data[EAppFormFields.MODEL]?.id ?? ''}
						rules={[{ required: true, message: t('error.required_field') }]}
					>
						<Select onChange={(value) => onChange([...path, EAppFormFields.MODEL], value)} options={llmModels}></Select>
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={<span>{t('visibility')}</span>}
								tooltipText={t('visibility.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.IS_HIDDEN]}
						initialValue={data[EAppFormFields.IS_HIDDEN]}
						rules={[]}
					>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={<span>{t('autonext')}</span>}
								tooltipText={t('autonext.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.AUTONEXT]}
						initialValue={data[EAppFormFields.AUTONEXT]}
						rules={[]}
					>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
					</Form.Item>
					<CustomCollapse
						className='mt-3'
						ghost
						expandIcon={(panelProps) => {
							return panelProps.isActive ? (
								<MinusCircleOutlined className='!text-base !font-bold opacity-60' />
							) : (
								<PlusCircleOutlined className='!text-base !font-bold opacity-60' />
							);
						}}
						items={[
							{
								label: (
									<div>
										<span className='font-bold opacity-60'>{t('advanced')}</span>
										<span className='ml-1 font-bold opacity-60 lowercase'>{t('settings')}</span>
									</div>
								),
								children: (
									<>
										<Form.Item
											label={<span>{t('max_tokens')}</span>}
											name={[...path, EAppFormFields.MAX_TOKENS]}
											initialValue={data[EAppFormFields.MAX_TOKENS]}
											rules={[]}
										>
											<InputNumber
												min='0'
												onChange={(value) => onChange([...path, EAppFormFields.MAX_TOKENS], value)}
												className='w-full'
											/>
										</Form.Item>
										<Form.Item
											label={
												<span>
													{t('content_conversation_type')}
													<span className='ml-1 text-error'>*</span>
												</span>
											}
											name={[...path, EAppFormFields.CONTENT_CONVERSATION_TYPE]}
											initialValue={data[EAppFormFields.CONTENT_CONVERSATION_TYPE]}
											rules={[{ required: true, message: t('error.required_field') }]}
										>
											<Select
												onChange={(value) => onChange([...path, EAppFormFields.CONTENT_CONVERSATION_TYPE], value)}
												options={[
													{
														label: 'Not converted',
														value: 'notConverted'
													},
													{
														label: 'to JSON',
														value: 'toJson'
													}
												]}
											></Select>
										</Form.Item>
										<Form.Item
											label={<span>{t('temperature')}</span>}
											name={[...path, EAppFormFields.TEMPERATURE]}
											initialValue={`${data[EAppFormFields.TEMPERATURE]}`}
											rules={[]}
										>
											<InputNumber
												min='0.1'
												max='1'
												step='0.1'
												onChange={(value) => onChange([...path, EAppFormFields.TEMPERATURE], value)}
												className='w-full'
												stringMode
											/>
										</Form.Item>
									</>
								)
							}
						]}
					></CustomCollapse>
				</>
			);
		}

		if (actionType === 'mailing_call') {
			return (
				<>
					<Form.Item
						label={EAppFormFields.NEXT_SLUG}
						name={[...path, EAppFormFields.NEXT_SLUG]}
						initialValue={data[EAppFormFields.NEXT_SLUG]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={EAppFormFields.BLOCK_TYPE}
						name={[...path, EAppFormFields.BLOCK_TYPE]}
						initialValue={data[EAppFormFields.BLOCK_TYPE]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							}
						]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
						name={[...path, EAppFormFields.BLOCK_NAME]}
						initialValue={data[EAppFormFields.BLOCK_NAME]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={<span>{t('description')}</span>}
						name={[...path, EAppFormFields.DESCRIPTION]}
						initialValue={data[EAppFormFields.DESCRIPTION]}
						rules={[
							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input.TextArea
							autoSize={{ minRows: 2, maxRows: 4 }}
							onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('dynamic_name')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('dynamic_name.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.SLUG]}
						initialValue={data[EAppFormFields.SLUG]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							},
							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('shipping_method')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('shipping_method.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.TYPES]}
						initialValue={[
							data[EAppFormFields.TYPES].telegram ? 'telegram' : null,
							data[EAppFormFields.TYPES].email ? 'email' : null
						]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							}
						]}
					>
						<Checkbox.Group
							onChange={(e) => {
								onChange([...path, EAppFormFields.TYPES], {
									telegram: e.includes('telegram'),
									email: e.includes('email')
								});
							}}
						>
							<Checkbox value='telegram'>Telegram</Checkbox>
							<Checkbox value='email'>Email</Checkbox>
						</Checkbox.Group>
					</Form.Item>
					<Form.Item
						label={
							<span>
								{t('to')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						name={[...path, EAppFormFields.EMAILS]}
						initialValue={data[EAppFormFields.EMAILS]}
						rules={[{ required: true, message: t('error.required_field') }]}
					>
						<Select
							mode='tags'
							tokenSeparators={[',']}
							onChange={(value) => {
								onChange([...path, EAppFormFields.EMAILS], value);
							}}
						></Select>
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('message')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('message.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.MESSAGE]}
						initialValue={data[EAppFormFields.MESSAGE]}
						className='mb-2'
						rules={[
							{
								required: true,
								message: t('error.required_field')
							},

							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input.TextArea
							autoSize={{ minRows: 4 }}
							ref={(input) => (textAreaRef.current = input?.resizableTextArea?.textArea || null)}
							rows={4}
							onChange={(e) => onChange([...path, EAppFormFields.MESSAGE], e.target.value)}
						/>
					</Form.Item>
					<div className='flex flex-wrap gap-x-2.5 gap-y-1.5 mb-4'>
						{slugs?.slice(0, actionIndex).map((slug) => (
							<div
								key={slug}
								className='cursor-pointer text-primary-font px-2 py-0.5 rounded-[33px] text-xs leading-5 bg-primary-bg-hover max-w-[60px] min-w-[60px] text-center overflow-hidden text-ellipsis whitespace-nowrap'
								onClick={() =>
									handleVariableInsert([...path, EAppFormFields.MESSAGE], slug, data[EAppFormFields.MESSAGE])
								}
							>
								{slug}
							</div>
						))}
					</div>
					<Form.Item name={[...path, EAppFormFields.AUTONEXT]} initialValue={true} rules={[]} hidden={true}>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={<span>{t('visibility')}</span>}
								tooltipText={t('visibility.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.IS_HIDDEN]}
						initialValue={data[EAppFormFields.IS_HIDDEN]}
						rules={[]}
						hidden={true}
					>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
					</Form.Item>
				</>
			);
		}

		if (actionType === 'api_call') {
			return (
				<>
					<Form.Item
						label={EAppFormFields.NEXT_SLUG}
						name={[...path, EAppFormFields.NEXT_SLUG]}
						initialValue={data[EAppFormFields.NEXT_SLUG]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={
							<span>
								{EAppFormFields.BLOCK_TYPE}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						name={[...path, EAppFormFields.BLOCK_TYPE]}
						initialValue={data[EAppFormFields.BLOCK_TYPE]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							}
						]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
						name={[...path, EAppFormFields.BLOCK_NAME]}
						initialValue={data[EAppFormFields.BLOCK_NAME]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('dynamic_name')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('dynamic_name.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.SLUG]}
						initialValue={data[EAppFormFields.SLUG]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							},
							{ min: 2, message: `${t('error.min_length')} 2` }
						]}
					>
						<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={<span>{t('description')}</span>}
						name={[...path, EAppFormFields.DESCRIPTION]}
						initialValue={data[EAppFormFields.DESCRIPTION]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
					>
						<Input.TextArea
							autoSize={{ minRows: 2, maxRows: 4 }}
							onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('result_view')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('result_view.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.TYPE]}
						initialValue={data[EAppFormFields.TYPE]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							}
						]}
					>
						<Radio.Group onChange={(e) => onChange([...path, EAppFormFields.TYPE], e.target.value)}>
							{resultViewOptions.map((option) => (
								<Radio key={`${option.label}_${option.value}`} value={option.value}>
									<span className='font-normal'>{option.label}</span>
								</Radio>
							))}
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label={
							<span>
								{EAppFormFields.URL}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						name={[...path, EAppFormFields.URL]}
						initialValue={data[EAppFormFields.URL]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							},
							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.URL], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={
							<span>
								{t('method')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						name={[...path, EAppFormFields.METHOD]}
						initialValue={data[EAppFormFields.METHOD]}
						rules={[{ required: true, message: t('error.required_field') }]}
					>
						<Select
							onChange={(value) => onChange([...path, EAppFormFields.METHOD], value)}
							options={METHOD_OPTIONS}
						></Select>
					</Form.Item>
					<Form.Item
						label={EAppFormFields.BODY}
						name={[...path, EAppFormFields.BODY]}
						initialValue={data[EAppFormFields.BODY]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
					>
						<Input.TextArea rows={4} onChange={(e) => onChange([...path, EAppFormFields.BODY], e.target.value)} />
					</Form.Item>
					<Form.List name={[...path, EAppFormFields.HEADERS]} initialValue={data.headers}>
						{(fields, { add, remove }) => {
							return (
								<div>
									<div className='mb-2 font-medium'>{t('headers')}</div>
									{fields.map(({ key, name, ...restField }, index) => {
										return (
											<div key={key} className='mb-4'>
												<div className='flex justify-between items-center mb-2.5'>
													<div>
														header {index + 1} <span className='ml-1 text-error'>*</span>
													</div>
													<DeleteOutlined
														className='text-error'
														onClick={() => {
															handleRemoveHeaderItem(name);
															remove(name);
														}}
													/>
												</div>
												<div key={key} className='flex flex-col grow gap-y-2.5'>
													<Form.Item
														{...restField}
														className='mb-0'
														name={[name, EAppFormFields.NAME]}
														rules={[
															{
																required: true,
																message: 'error.required_field'
															},
															{ min: 1, message: `${t('error.min_length')} 1` }
														]}
													>
														<Input
															placeholder={EAppFormFields.NAME}
															onChange={(e) =>
																onChange(
																	[...path, EAppFormFields.HEADERS, `${name}`, EAppFormFields.NAME],
																	e.target.value
																)
															}
														/>
													</Form.Item>
													<Form.Item
														{...restField}
														className='mb-0'
														name={[name, EAppFormFields.VALUE]}
														rules={[
															{
																required: true,
																message: 'error.required_field'
															},
															{ min: 1, message: `${t('error.min_length')} 1` }
														]}
													>
														<Input
															placeholder={EAppFormFields.VALUE}
															onChange={(e) =>
																onChange(
																	[...path, EAppFormFields.HEADERS, `${name}`, EAppFormFields.VALUE],
																	e.target.value
																)
															}
														/>
													</Form.Item>
												</div>
											</div>
										);
									})}
									<Form.Item>
										<Button
											type='dashed'
											onClick={() => {
												add();
												handleAddHeaderItem();
											}}
											icon={<PlusOutlined />}
										/>
									</Form.Item>
								</div>
							);
						}}
					</Form.List>
					<Form.Item
						label={
							<LabelWithInfo
								label={<span>{t('visibility')}</span>}
								tooltipText={t('visibility.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.IS_HIDDEN]}
						initialValue={data[EAppFormFields.IS_HIDDEN]}
						rules={[]}
					>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={<span>{t('autonext')}</span>}
								tooltipText={t('autonext.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.AUTONEXT]}
						initialValue={data[EAppFormFields.AUTONEXT]}
						rules={[]}
					>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
					</Form.Item>
					<Form.Item
						label={<span>{t('is_xml')}</span>}
						name={[...path, EAppFormFields.IS_XML]}
						initialValue={data[EAppFormFields.IS_XML]}
						rules={[]}
					>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_XML], e)} />
					</Form.Item>
					<Form.Item
						label={<span>{EAppFormFields.JSONATA}</span>}
						name={[...path, EAppFormFields.JSONATA]}
						initialValue={data[EAppFormFields.JSONATA]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
					>
						<Input.TextArea rows={4} onChange={(e) => onChange([...path, EAppFormFields.JSONATA], e.target.value)} />
					</Form.Item>
				</>
			);
		}

		if (actionType === 'vault_call') {
			return (
				<>
					<Form.Item
						label={EAppFormFields.NEXT_SLUG}
						name={[...path, EAppFormFields.NEXT_SLUG]}
						initialValue={data[EAppFormFields.NEXT_SLUG]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={EAppFormFields.BLOCK_TYPE}
						name={[...path, EAppFormFields.BLOCK_TYPE]}
						initialValue={data[EAppFormFields.BLOCK_TYPE]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							}
						]}
						hidden={true}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
						name={[...path, EAppFormFields.BLOCK_NAME]}
						initialValue={data[EAppFormFields.BLOCK_NAME]}
						rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
					>
						<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={
							<LabelWithInfo
								label={
									<span>
										{t('dynamic_name')}
										<span className='ml-1 text-error'>*</span>
									</span>
								}
								tooltipText={t('dynamic_name.description')}
							></LabelWithInfo>
						}
						name={[...path, EAppFormFields.SLUG]}
						initialValue={data[EAppFormFields.SLUG]}
						rules={[
							{
								required: true,
								message: t('error.required_field')
							},
							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
					</Form.Item>
					<Form.Item
						label={<span>{t('description')}</span>}
						name={[...path, EAppFormFields.DESCRIPTION]}
						initialValue={data[EAppFormFields.DESCRIPTION]}
						rules={[
							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input.TextArea
							autoSize={{ minRows: 2, maxRows: 4 }}
							onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
						/>
					</Form.Item>
					<VaultSelect
						name={[...path, EAppFormFields.VAULT]}
						initialValue={data[EAppFormFields.VAULT]?.id}
						onChange={(value) => onChange([...path, EAppFormFields.VAULT], value)}
					/>
					<Form.Item name={[...path, EAppFormFields.AUTONEXT]} initialValue={true} hidden={true}>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
					</Form.Item>
					<Form.Item name={[...path, EAppFormFields.IS_HIDDEN]} initialValue={false} hidden={true}>
						<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
					</Form.Item>
				</>
			);
		}

		return null;
	}, [data, path, actionId, actionType, llmModels, dispatch]);

	return <>{renderFields}</>;
};

export const ActionFormItem = memo(ActionFormItemComponent);
