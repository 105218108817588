import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { TThreadDTO } from '../api/type';
import { threadApi } from '../api/threadApi';
import { TMessage } from './types';

type TThreadState = {
	thread: TThreadDTO | null;
	messages: TMessage[];
	totalMessages: number;
	isImgViewerOpen: boolean;
	openUploadFiles: boolean;
	selectedImg: string | null;
};

const initialState: TThreadState = {
	thread: null,
	messages: [],
	totalMessages: 0,
	isImgViewerOpen: false,
	openUploadFiles: false,
	selectedImg: null
};

export const threadSlice = createSlice({
	name: 'thread',
	initialState,
	reducers: {
		setIsImgViewerOpen: (state, action: PayloadAction<boolean>) => {
			state.isImgViewerOpen = action.payload;
		},
		setOpenUploadFiles: (state, action: PayloadAction<boolean>) => {
			state.openUploadFiles = action.payload;
		},
		setSelectedImg: (state, action: PayloadAction<string | null>) => {
			state.selectedImg = action.payload;
		},
		setMessagesList: (state, action: PayloadAction<{ messages: TMessage[]; totalDocs: number }>) => {
			state.messages = action.payload.messages;
			state.totalMessages = action.payload.totalDocs;
		},
		updateMessagesList: (state, action: PayloadAction<{ message: TMessage[]; totalDocs: number }>) => {
			state.messages = [...state.messages, ...action.payload.message];
			state.totalMessages = action.payload.totalDocs;
		},
		resetThread: () => {
			return { ...initialState };
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(threadApi.endpoints.getThreadById.matchFulfilled, (state, action: PayloadAction<TThreadDTO>) => {
			return {
				...state,
				thread: action.payload
			};
		});
		builder.addMatcher(threadApi.endpoints.createMessage.matchFulfilled, (state, action: PayloadAction<TMessage>) => {
			state.messages = [action.payload, ...state.messages];
		});
	}
});

export const {
	resetThread,
	updateMessagesList,
	setMessagesList,
	setIsImgViewerOpen,
	setOpenUploadFiles,
	setSelectedImg
} = threadSlice.actions;
