import { memo, FC, ChangeEvent } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { ConfigProvider, Input, InputProps } from 'antd';
import cx from './Search.module.css';
import cn from 'classnames';

type TSearchProps = InputProps & {
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	className?: string;
	defaultValue?: string;
};

export const Search: FC<TSearchProps> = memo(({ onChange, placeholder, className, defaultValue, size, ...props }) => {
	return (
		<ConfigProvider
			theme={{
				inherit: false
			}}
		>
			<div
				className={cn(
					'rounded-md border border-solid border-gray-bg-hover focus-within:border-gray-border bg-gray-bg-hover search-panel',
					className
				)}
			>
				<Input.Search
					variant='borderless'
					placeholder={placeholder}
					maxLength={100}
					onChange={onChange}
					defaultValue={defaultValue ?? ''}
					className={cx.root}
					suffix={<SearchOutlined />}
					size={size}
				/>
			</div>
		</ConfigProvider>
	);
});
