import { axiosInstance } from 'shared/api/axiosInstance';
import { baseApi } from 'shared/api/baseApi';
import { USER_TAG } from 'shared/api/tags';
import { TUser } from '../model/types';
import qs from 'qs';

export const userApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getUser: build.query<{ user: TUser }, void>({
			query: () => ({
				url: `users/me`
			}),
			providesTags: [USER_TAG]
		}),
		changePassword: build.mutation<
			any,
			{
				oldPassword: string;
				newPassword: string;
			}
		>({
			query: ({ oldPassword, newPassword }) => {
				return {
					url: 'users/me/change-password',
					method: 'POST',
					data: { oldPassword, newPassword }
				};
			},
			invalidatesTags: [USER_TAG]
		}),
		uploadAvatar: build.mutation<{ doc: { id: string; url: string } }, { formData: FormData }>({
			query: ({ formData }) => {
				return {
					url: `users_avatars`,
					method: 'POST',
					data: formData
				};
			}
		}),
		deleteAvatar: build.mutation<any, { fileId: string }>({
			query: ({ fileId }) => {
				const stringifiedQuery = qs.stringify(
					{
						where: {
							id: {
								equals: fileId
							}
						}
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `users_avatars${stringifiedQuery}`,
					method: 'DELETE'
				};
			}
		}),
		updateFirstAndMiddleName: build.mutation<
			any,
			{
				firstName: string;
				lastName: string;
				userId: string;
			}
		>({
			query: ({ firstName, lastName, userId }) => {
				return {
					url: `users/${userId}`,
					method: 'PATCH',
					data: { firstName, lastName }
				};
			},
			invalidatesTags: [USER_TAG]
		})
	})
});

export const {
	useGetUserQuery,
	useChangePasswordMutation,
	useUploadAvatarMutation,
	useUpdateFirstAndMiddleNameMutation,
	useDeleteAvatarMutation
} = userApi;

export async function getUserData() {
	const data = await axiosInstance.get('users/me', {
		withCredentials: true
	});
	return data.data;
}

export async function signup(email: string, password: string, policy: boolean, phone: string, promoCode?: string) {
	const data = await axiosInstance.post(
		'users/register',
		{
			email,
			password,
			policy,
			phone,
			...(promoCode ? { promoCode } : {})
		},
		{
			withCredentials: true
		}
	);
	return data.data;
}

export async function signupDemo() {
	const data = await axiosInstance.post('users/register-demo', {
		withCredentials: true
	});
	return data.data;
}

export async function setOnboarded(pageName: string, userId: string) {
	return await axiosInstance.patch(
		`users/${userId}`,
		{
			onboarded: { [pageName]: true }
		},
		{
			withCredentials: true
		}
	);
}

export async function setDefaultThreadModel(modelId: string, userId: string) {
	return await axiosInstance.patch(
		`users/${userId}`,
		{
			defaultThreadModel: modelId
		},
		{
			withCredentials: true
		}
	);
}

export async function forgotPassword(email: string) {
	const data = await axiosInstance.post(
		'users/forgot-password',
		{
			email
		},
		{
			withCredentials: true
		}
	);
	return data.data;
}

export async function resetPassword(token: string, password: string) {
	const data = await axiosInstance.post(
		'users/reset-password',
		{
			token,
			password
		},
		{
			withCredentials: true
		}
	);
	return data.data;
}

export async function login(email: string, password: string) {
	const data = await axiosInstance.post(
		'users/login',
		{
			email,
			password
		},
		{
			withCredentials: true
		}
	);
	return data.data;
}

export async function logout() {
	const data = await axiosInstance.post(
		'users/logout',
		{},
		{
			withCredentials: true
		}
	);
	return data.data;
}

export const setLocale = async (locale: string) => {
	await axiosInstance.post(
		'payload-preferences/locale',
		{ value: locale },
		{
			withCredentials: true
		}
	);
};
