import React, { memo, useEffect, useMemo, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import {
	ApiCallActionIcon,
	GptActionIcon,
	JSONataCallActionIcon,
	MailingCallActionIcon,
	LlmActionIcon,
	PlusOutlined,
	VaultActionIcon
} from 'shared/ui/Icons';
import { PopoverProps } from 'antd';
import { useAppDispatch } from 'shared/model';
import { changeActionType } from '../../model/slice';
import { TBlockTypes } from '../../model/types';
import { useTranslate } from 'shared/lib/i18n';
import cn from 'classnames';
import { CustomCollapse, CustomPopover } from 'shared/ui';

const InitialNodeComponent = (props: NodeProps) => {
	const t = useTranslate();
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(true);
	const { selected } = props;

	useEffect(() => {
		if (selected) {
			setOpen(true);
		}
	}, [selected]);

	const handleOpenChange: PopoverProps['onOpenChange'] = (newOpen, e) => {
		setOpen(newOpen);
	};

	const selectActionType = (actionType: Parameters<typeof onChange>[0]) => {
		setOpen(false);
		onChange(actionType);
	};

	const content = useMemo(() => {
		return (
			<div className='min-w-[220px] pt-3'>
				<div
					onClick={(e) => {
						e.preventDefault();
						selectActionType('gpt_call');
					}}
					className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
				>
					<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
						<GptActionIcon />
					</div>
					<span>{t('gpt_call')}</span>
				</div>
				<div
					onClick={(e) => {
						e.preventDefault();
						selectActionType('api_call');
					}}
					className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
				>
					<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
						<ApiCallActionIcon />
					</div>
					<span>{t('api_call')}</span>
				</div>
				<div
					onClick={(e) => {
						e.preventDefault();
						selectActionType('mailing_call');
					}}
					className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
				>
					<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
						<MailingCallActionIcon />
					</div>
					<span>{t('mailing_call')}</span>
				</div>
				<div
					onClick={(e) => {
						e.preventDefault();
						selectActionType('vault_call');
					}}
					className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
				>
					<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
						<VaultActionIcon />
					</div>
					<span>{t('vault_call')}</span>
				</div>
				<CustomCollapse
					className='mt-3'
					expandIconPosition={'end'}
					ghost
					items={[
						{
							label: <span className='ml-3 font-medium'>{t('advanced')}</span>,
							children: (
								<>
									<div
										onClick={(e) => {
											e.preventDefault();
											selectActionType('jsonata_call');
										}}
										className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
									>
										<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
											<JSONataCallActionIcon />
										</div>
										<span>JSONata</span>
									</div>
									<div
										onClick={(e) => {
											e.preventDefault();
											selectActionType('llm_call');
										}}
										className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
									>
										<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
											<LlmActionIcon />
										</div>
										<span>LLM</span>
									</div>
								</>
							)
						}
					]}
				/>
			</div>
		);
	}, []);

	const onChange = async (value: TBlockTypes) => {
		await dispatch(changeActionType({ id: props.id, newType: value }));
	};

	return (
		<div
			className={cn('flex items-center justify-center border border-dashed border-gray-border rounded p-px bg-white', {
				'border-primary-active border-2 !border-solid': selected || open
			})}
		>
			<CustomPopover
				destroyTooltipOnHide={true}
				trigger='click'
				open={open}
				content={content}
				onOpenChange={handleOpenChange}
				arrow={false}
			>
				<div onClick={(e) => e.preventDefault()} className='min-w-[20px] min-h-[20px] flex items-center justify-center'>
					<PlusOutlined className='opacity-25 text-[8px]' />
				</div>
			</CustomPopover>
			<Handle type='target' position={Position.Top} className='w-16 !bg-teal-500 h-auto' isConnectable={false} />
		</div>
	);
};

export const InitialNode = memo(InitialNodeComponent);
